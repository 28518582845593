import { Outlet } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import { clearAllCaches, useAuth } from '@/common'
import { authStore as useAuthStore } from "@/app"

interface PrivateRouteProps {
  allowedRoles?: string[]
}

export const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const { userAllowedModules } = useAuth()
  const { idToken, refreshToken, userAuthenticated } = useAuthStore(
    (s) => ({
      idToken: s.id_token,
      refreshToken: s.refresh_token,
      userAuthenticated: s.isUserAuthenticated,
    }),
    shallow,
  )

  if (allowedRoles && userAllowedModules) {
    if (!userAllowedModules || userAllowedModules.length === 0) {
      console.error('No roles')

      clearAllCaches()
      return window.location.href = 'https://www.qas.acer-loginunico.com/'
    }

    const isAllowed =
      userAllowedModules.includes('*') ||
      userAllowedModules.some((role) => allowedRoles.includes(role))

    if (!isAllowed) {
      console.error('Not allowed')

      return window.location.href = 'https://www.qas.acer-loginunico.com/'
    }

    const isAuthenticated = idToken && refreshToken && userAuthenticated

    if (!isAuthenticated) {
      clearAllCaches()
      return window.location.href = 'https://www.qas.acer-loginunico.com/'
    }

    return (
      <Outlet />
    )
  }

  return (
    <Outlet />
  )
}


import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { RefreshLoginResponse } from '../../types'

import { authStore } from '@/app'
import rolesStore from '@/app/stores/roles'
import { clearAllCaches } from '@/common'
import { api } from '@/services'

type RefreshLoginMutation = {
  refresh_token: string
  user_sso_id: string
  redirectPage: string | null
}

export const useRefreshLoginToken = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async ({ refresh_token, user_sso_id }: RefreshLoginMutation) => {
      console.log(refresh_token, user_sso_id)
      return api.post<RefreshLoginResponse>(`/auth/login`, {
        refresh_token: refresh_token,
        user_sso_id: user_sso_id,
      })
    },
    onSuccess: ({ data }, { redirectPage }) => {
      api.defaults.headers.common.Authorization = `Bearer ${data.id_token}`

      // Atualizar auth stores
      authStore.setState(
        {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          id_token: data.id_token,
          isUserAuthenticated: true,
        },
        false,
        'sign-in',
      )

      rolesStore.getState().getUserRoles(data.id_token)
      rolesStore.setState({ userRoles: data.permissions })

      toast.success('Usuário validado com sucesso!')

      if (redirectPage) {
        return navigate(`/${redirectPage}`)
      } else {
        navigate(`/home`)
      }
    },
    onError: (err) => {
      console.error('Error refreshing login token', err)

      toast.error('Erro ao validar usuário. Por favor, faça login novamente.')

      clearAllCaches()
      window.location.href = 'https://www.qas.acer-loginunico.com/'
    },
  })
}

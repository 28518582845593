import { ReactNode } from 'react'

import { AcerLinksHeader } from '@fitec/acer-header'
import { Content } from 'antd/es/layout/layout'

import * as S from './styles'

import { authStore } from '@/app'
import { useDocumentTitle, useUserId } from '@/common'
import { Header } from '@/components'
import { clearAllCaches } from '@/common'
import { authStore as useAuthStore } from "@/app"
import { shallow } from 'zustand/shallow'

interface PageLayoutProps {
  title?: string
  children: ReactNode
  sidebar?: ReactNode
}

const PageLayout = ({ title, sidebar, children }: PageLayoutProps) => {
  useDocumentTitle(title)

  const { idToken, refreshToken, userAuthenticated } = useAuthStore(
    (s) => ({
      idToken: s.id_token,
      refreshToken: s.refresh_token,
      userAuthenticated: s.isUserAuthenticated,
    }),
    shallow,
  )

  if (
    idToken === undefined ||
    idToken === null ||
    refreshToken === undefined ||
    refreshToken === null ||
    !userAuthenticated) {
    clearAllCaches()
    return window.location.href = 'https://www.qas.acer-loginunico.com/'
  }

  const userId = useUserId()
  const auth = authStore((state) => state)

  return (
    <S.Container>
      <AcerLinksHeader
        idToken={auth.id_token as string}
        redirectInfo={{
          refreshToken: auth.refresh_token as string,
          userId: userId,
        }}
      />
      <Header />

      <S.SidebarContainer>
        {sidebar}

        <S.ContentContainer>
          <Content>{children}</Content>
        </S.ContentContainer>
      </S.SidebarContainer>
    </S.Container>
  )
}

export { PageLayout }
